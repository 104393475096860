<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="34"
    viewBox="0 0 32 34"
  >
    <defs>
      <path
        id="3izyvb1w0a"
        d="M12 29.857V25.1c-6.68-.344-12-5.842-12-12.543C0 5.63 5.68 0 12.667 0c6.986 0 12.666 5.63 12.666 12.556 0 6.543-4.586 13.125-11.426 16.39L12 29.856zm.667-27.214c-5.52 0-10 4.441-10 9.913s4.48 9.913 10 9.913h2v3.04c4.853-3.04 8-8.036 8-12.953 0-5.472-4.48-9.913-10-9.913zm-1.334 15.2H14v2.643h-2.667v-2.643zM14 15.86h-2.667c0-4.295 4-3.965 4-6.608 0-1.454-1.2-2.644-2.666-2.644C11.2 6.608 10 7.798 10 9.252H7.333c0-2.921 2.387-5.287 5.334-5.287C15.613 3.965 18 6.331 18 9.252c0 3.304-4 3.635-4 6.608z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0L32 0 32 32.571 0 32.571z"
              transform="translate(-32 -942) translate(0 910) translate(32 32.571)"
            />
            <g
              transform="translate(-32 -942) translate(0 910) translate(32 32.571) translate(2.667 1.357)"
            >
              <mask id="itgo5vygrb" fill="#fff">
                <use xlink:href="#3izyvb1w0a" />
              </mask>
              <use
                fill="#424242"
                fill-rule="nonzero"
                xlink:href="#3izyvb1w0a"
              />
              <g fill="#90A4AE" mask="url(#itgo5vygrb)">
                <path
                  d="M0 0H32V32.571H0z"
                  transform="translate(-2.667 -1.357)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
