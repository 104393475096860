import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";

import { millisInMinutes } from "@/utils/dateUtils";

import { AuthState, AuthModule, AuthActions } from "./authModule";
import { MetadataState, MetadataModule } from "./metadataModule";

// define your typings for the store state
export interface RootState {
  auth: AuthState;
  meta: MetadataState;
}

// define injection key
export const key: InjectionKey<Store<RootState>> = Symbol();

export const store = createStore<RootState>({
  modules: { auth: AuthModule, meta: MetadataModule },
});

async function runTabCounters() {
  async function incrementTabCount() {
    let tabCounter = +(localStorage.getItem("tabCounter") || "0");
    if (tabCounter <= 0) {
      tabCounter = 0;
      // await store.dispatch(AuthActions.LOG_OUT);
    }
    tabCounter++;
    localStorage.setItem("tabCounter", tabCounter.toString());

    return tabCounter;
  }
  function decrementTabCounter() {
    let tabCounter = +(localStorage.getItem("tabCounter") || "1");
    tabCounter--;
    localStorage.setItem("tabCounter", tabCounter.toString());

    return tabCounter;
  }

  incrementTabCount();

  window.addEventListener("beforeunload", async (e) => {
    if (decrementTabCounter() <= 0) {
      localStorage.setItem("lastLogOut", new Date().toString());
      // await store.dispatch(AuthActions.LOG_OUT);
    }
  });
  store.dispatch(AuthActions.LOG_IN);
  // store.dispatch(AuthActions.CREATE_CLIENT, false);
}

localStorage.setItem("logout", "false");
window.onstorage = async (event: StorageEvent) => {
  const { key, newValue } = event;
  console.log("LSSSS", key, newValue);
  if (key === "logout" && newValue === "true") {
    await store.dispatch(AuthActions.LOG_OUT);
  }
};
runTabCounters();

localStorage.setItem("logout", "false");
window.onstorage = async (event: StorageEvent) => {
  const { key, newValue } = event;
  if (key === "logout" && newValue === "true") {
    await store.dispatch(AuthActions.LOG_OUT);
  }
};

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}
