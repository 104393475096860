import { Module } from "vuex";

import { RootState } from "./store";

export interface MetadataState {
  breadcrumbsName: string;
  prevRouteName: string;
}

export enum MetadataMutations {
  SET_BREAD_CRUMBS_NAME = "SET_BREAD_CRUMBS_NAME",
  SET_PREV_ROUTE_NAME = "SET_PREV_ROUTE_NAME",
}

export enum MetadataActions {}

const metadataModule: Module<MetadataState, RootState> = {
  state: () => {
    return {
      breadcrumbsName: "",
      prevRouteName: "",
    };
  },
  actions: {},
  mutations: {
    [MetadataMutations.SET_BREAD_CRUMBS_NAME](
      state: MetadataState,
      payload: string
    ) {
      state.breadcrumbsName = payload;
    },
    [MetadataMutations.SET_PREV_ROUTE_NAME](
      state: MetadataState,
      payload: string
    ) {
      state.prevRouteName = payload;
    },
  },
  getters: {},
};

export const MetadataModule = metadataModule;
