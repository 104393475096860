import { computed, defineComponent } from "vue";
import NeedSupportComponent from "@/components/NeedSupportComponent/NeedSupportComponent.vue";
import { useStore } from "@/store/store";
import { AuthActions, ErrorAction } from "@/store/authModule";
import Logo from "@/assets/icons/Logo.vue";

export default defineComponent({
  name: "ErrorScreen",
  props: {},
  components: {
    Logo,
    NeedSupportComponent,
  },
  setup(props) {
    const store = useStore();

    return {
      error: computed(() => store.state.auth.message),
      buttonClick: () => {
        switch (store.state.auth.message?.action) {
          case ErrorAction.LogIn:
            {
              //store.dispatch(AuthActions.LOG_IN);
              window.location.href = window.location.pathname;
            }
            break;
          case ErrorAction.LogInWithLogOut:
            {
              store.dispatch(AuthActions.LOG_OUT);
            }
            break;
          case ErrorAction.ResetPassword:
            {
              store.dispatch(AuthActions.LOG_IN, {
                params: { mode: "reset-password" },
              });
            }
            break;
        }
      },
    };
  },
});
