<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="kvus46kmla"
        d="M14 5l-1.41 1.41L14.17 8H6v2h8.17l-1.58 1.58L14 13l4-4-4-4zM2 2h7V0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h7v-2H2V2z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0H24V24H0z"
                transform="translate(-1141 -595) translate(1117 575) translate(0 8) translate(24 12)"
              />
              <g
                transform="translate(-1141 -595) translate(1117 575) translate(0 8) translate(24 12) translate(3 3)"
              >
                <mask id="oj0b7a717b" fill="#fff">
                  <use xlink:href="#kvus46kmla" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#kvus46kmla" />
                <g fill="#90A4AE" mask="url(#oj0b7a717b)">
                  <path d="M0 0H24V24H0z" transform="translate(-3 -3)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
