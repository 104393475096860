import Vue, { computed, defineComponent, reactive } from "vue";
import { useStore } from "./store/store";

import LogoIcon from "./assets/icons/Logo.vue";
import LogoMiniIcon from "./assets/icons/LogoMini.vue";
import AssignmentIcon from "./assets/icons/menu/Assignment.vue";
import CorporateFareIcon from "./assets/icons/menu/CorporateFare.vue";
import DashboardIcon from "./assets/icons/menu/Dashboard.vue";
import DescriptionIcon from "./assets/icons/menu/Description.vue";
import GroupIcon from "./assets/icons/menu/Group.vue";
import MenuIcon from "./assets/icons/menu/Menu.vue";
import NotificationsIcon from "./assets/icons/menu/Notifications.vue";
import SettingsIcon from "./assets/icons/menu/Settings.vue";
import TabletMacIcon from "./assets/icons/menu/TabletMac.vue";

import LoginComponent from "./components/LoginComponent/LoginComponent.vue";
import BreadCrumbs from "./components/BreadCrumbs/BreadCrumbs.vue";
import ErrorScreen from "./components/ErrorScreen/ErrorScreen.vue";
import { useRoute, useRouter } from "vue-router";

import useUserRoles, { UserRole } from "@/uses/useUserRoles";

export default defineComponent({
  components: {
    LogoIcon,
    LogoMiniIcon,
    AssignmentIcon,
    CorporateFareIcon,
    DashboardIcon,
    DescriptionIcon,
    GroupIcon,
    MenuIcon,
    NotificationsIcon,
    SettingsIcon,
    TabletMacIcon,
    LoginComponent,
    BreadCrumbs,
    ErrorScreen,
  },
  setup() {
    const store = useStore();
    const state = reactive({ leftIsCollapse: false });
    const route = useRoute();

    return {
      state,
      ...useUserRoles(store),
      currentActivePath: computed(() => {
        let path = route.path;
        if (path.endsWith("/")) path = path.substring(0, path.length - 1);

        const parts = path.split("/");

        if (parts.length < 2) {
          return "/";
        }

        // root pages
        if (parts.length === 2) {
          return path;
        }
        // subjects first level subpage
        if (
          parts.length === 3 &&
          parts[1] === "subjects" &&
          (parts[2] === "hipal-pro" || parts[2] === "psycoxx")
        ) {
          return path;
        }

        // cut last parts
        return parts.slice(0, 2).join("/");
      }),
      isAuth: computed(() => store.state.auth.isAuthenticated),
      error: computed(() => store.state.auth.message),
      showLink: computed(() => (link: string) =>
        store.getters.showSitePartForUser(link)
      ),
      toggleMenu: () => (state.leftIsCollapse = !state.leftIsCollapse),
    };
  },
});
