<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="bc011w6yka"
        d="M10 4V0H0v18h20V4H10zM8 16H2v-2h6v2zm0-4H2v-2h6v2zm0-4H2V6h6v2zm0-4H2V2h6v2zm10 12h-8V6h8v10zm-2-8h-4v2h4V8zm0 4h-4v2h4v-2z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-24 -204) translate(0 184) translate(24 20)"
            />
            <path
              d="M3 3L21 3 21 21 3 21z"
              transform="translate(-24 -204) translate(0 184) translate(24 20)"
            />
            <g
              transform="translate(-24 -204) translate(0 184) translate(24 20) translate(2 3)"
            >
              <mask id="xpngm1d15b" fill="#fff">
                <use xlink:href="#bc011w6yka" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#bc011w6yka" />
              <g fill="#B0BEC5" mask="url(#xpngm1d15b)">
                <path d="M0 0H24V24H0z" transform="translate(-2 -3)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
