let instance: any = null;

export function setInstance(appInstance: any) {
  instance = appInstance;
}

function notify(params: any) {
  if (instance) {
    instance?.config?.globalProperties?.$notify(params);
  }
}

export function showError(message: string, title = "Internal Server Error") {
  notify({
    title,
    message,
    type: "error",
  });
}

export function showErrorOptional(message: string, error: any, title = "Internal Server Error") {
  if (!error.notAuthenticated) {
    showError(message, title);
  }
}
