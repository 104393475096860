import { useStore } from "@/store/store";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "BreadCrumbs",
  props: {},
  components: {},
  setup(props) {
    const route = useRoute();
    const store = useStore();

    const metadataName = computed(() => store.state.meta.breadcrumbsName);

    return {
      metadataName,
      breadCrumbs: computed(() => {
        const result = [{ path: route.path, name: route.name }];

        if (route.meta.parent) {
          const { parent, parentLink, name } = route.meta;

          if (name && metadataName.value) {
            result[0].name = name.replace("{{name}}", metadataName.value);
          }

          result.unshift({
            path: parentLink,
            name: parent,
          });
        }

        return result;
      }),
    };
  },
});
