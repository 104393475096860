<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="esvdwgr89a"
        d="M16 2v2h-4V2h4M6 2v6H2V2h4m10 8v6h-4v-6h4M6 14v2H2v-2h4M18 0h-8v6h8V0zM8 0H0v10h8V0zm10 8h-8v10h8V8zM8 12H0v6h8v-6z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-24 -140) translate(0 120) translate(24 20)"
            />
            <path
              d="M3 3L21 3 21 21 3 21z"
              transform="translate(-24 -140) translate(0 120) translate(24 20)"
            />
            <g
              transform="translate(-24 -140) translate(0 120) translate(24 20) translate(3 3)"
            >
              <mask id="9kb0v2f3hb" fill="#fff">
                <use xlink:href="#esvdwgr89a" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#esvdwgr89a" />
              <g fill="#1565C0" mask="url(#9kb0v2f3hb)">
                <path d="M0 0H24V24H0z" transform="translate(-3 -3)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
