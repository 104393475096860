import { supportEmail } from "@/constants";
import { defineComponent } from "vue";
import IcoContactSupport from "@/assets/icons/IcoContactSupport.vue";

export default defineComponent({
  name: "NeedSupportComponent",
  props: {},
  components: { IcoContactSupport },
  setup() {
    return { supportEmail };
  },
});
