<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path id="1u2cgqn08a" d="M0 12h18v-2H0v2zm0-5h18V5H0v2zm0-7v2h18V0H0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0L24 0 24 24 0 24z"
                transform="translate(-292 -24) translate(264) translate(0 4) translate(28 20)"
              />
              <g
                transform="translate(-292 -24) translate(264) translate(0 4) translate(28 20) translate(3 6)"
              >
                <mask id="a4xehre0sb" fill="#fff">
                  <use xlink:href="#1u2cgqn08a" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#1u2cgqn08a" />
                <g fill="#B0BEC5" mask="url(#a4xehre0sb)">
                  <path d="M0 0H24V24H0z" transform="translate(-3 -6)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
