<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="9w8g2vv40a"
        d="M7 8.75c-2.34 0-7 1.17-7 3.5V14h14v-1.75c0-2.33-4.66-3.5-7-3.5zM2.34 12c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H2.34zM7 7c1.93 0 3.5-1.57 3.5-3.5S8.93 0 7 0 3.5 1.57 3.5 3.5 5.07 7 7 7zm0-5c.83 0 1.5.67 1.5 1.5S7.83 5 7 5s-1.5-.67-1.5-1.5S6.17 2 7 2zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V14h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM13 7c1.93 0 3.5-1.57 3.5-3.5S14.93 0 13 0c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-24 -332) translate(0 312) translate(24 20)"
            />
            <path
              d="M3 3L21 3 21 21 3 21z"
              transform="translate(-24 -332) translate(0 312) translate(24 20)"
            />
            <g
              transform="translate(-24 -332) translate(0 312) translate(24 20) translate(2 5)"
            >
              <mask id="ux3k9uwlsb" fill="#fff">
                <use xlink:href="#9w8g2vv40a" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#9w8g2vv40a" />
              <g fill="#B0BEC5" mask="url(#ux3k9uwlsb)">
                <path d="M0 0H24V24H0z" transform="translate(-2 -5)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
