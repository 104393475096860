import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
} from "vue";
import { useStore } from "@/store/store";

import ChevronDownIcon from "@/assets/icons/menu/ChevronDown.vue";
import LogoutIcon from "@/assets/icons/menu/Logout.vue";
import { AuthActions } from "@/store/authModule";
import { getUserRoleTitle, UserRole } from "@/uses/useUserRoles";

export default defineComponent({
  name: "LoginComponent",
  props: {},
  components: { ChevronDownIcon, LogoutIcon },
  setup() {
    const state = reactive({ dialogVisible: false, dropdownWidth: 200 });
    const store = useStore();

    const updateWidth = () => {
      setTimeout(() => {
        const el = document.querySelector(".login-panel");
        if (el) {
          state.dropdownWidth = Math.max(el?.clientWidth, 150);
        }
      }, 500);
    };

    onMounted(() => {
      updateWidth();
    });

    window.addEventListener("resize", updateWidth);

    return {
      state,
      user: computed(() => store.state.auth.user),
      userRoleName: computed(() =>
        store.state.auth.userRoles?.map(getUserRoleTitle).join(", ")
      ),
      logout: () => store.dispatch(AuthActions.LOG_OUT),
      openLogoutPopup: () => {
        state.dialogVisible = true;
      },
    };
  },
});
