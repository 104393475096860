import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")

  return (_openBlock(), _createBlock(_component_el_breadcrumb, { separator: "/" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.breadCrumbs, (item, index) => {
        return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
          class: { 'breadcrumb-gray': index === 0 && _ctx.breadCrumbs.length > 1 },
          key: item.name,
          to: { path: item.path }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.name), 1)
          ]),
          _: 2
        }, 1032, ["class", "to"]))
      }), 128))
    ]),
    _: 1
  }))
}