import Vue, { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";

import App from "./App.vue";
import router from "./router";
import { store, key } from "./store/store";
import { setInstance } from "./utils/notificationUtils";

const app = createApp(App)
  .use(ElementPlus)
  .use(store, key)
  .use(router);

setInstance(app);

app.mount("#app");
