<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="0lawrvmkga"
        d="M16 2h-4.18C11.4.84 10.3 0 9 0 7.7 0 6.6.84 6.18 2H2C.9 2 0 2.9 0 4v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7-.25c.22 0 .41.1.55.25.12.13.2.31.2.5 0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-.19.08-.37.2-.5.14-.15.33-.25.55-.25zM16 18H2V4h14v14zM9 5C7.35 5 6 6.35 6 8s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm0 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-6 6.47V17h12v-1.53c0-2.5-3.97-3.58-6-3.58s-6 1.07-6 3.58zM5.31 15C6 14.44 7.69 13.88 9 13.88s3.01.56 3.69 1.12H5.31z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-24 -460) translate(0 440) translate(24 20)"
            />
            <path
              d="M3 3L21 3 21 21 3 21z"
              transform="translate(-24 -460) translate(0 440) translate(24 20)"
            />
            <g
              transform="translate(-24 -460) translate(0 440) translate(24 20) translate(3 2)"
            >
              <mask id="5e9g5v08fb" fill="#fff">
                <use xlink:href="#0lawrvmkga" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#0lawrvmkga" />
              <g fill="#B0BEC5" mask="url(#5e9g5v08fb)">
                <path d="M0 0H24V24H0z" transform="translate(-3 -2)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
