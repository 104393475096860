import { Module } from "vuex";

import createAuth0Client, { Auth0Client, User } from "@auth0/auth0-spa-js";

import intersection from "lodash-es/intersection";

import { RootState, store } from "./store";

import { domain, clientId, clientSecret } from "./../../auth_config.json";

import router from "./../router";
import { parseGetParams } from "@/utils/urlUtils";
import { supportEmail } from "@/constants";
import { UserRole } from "@/uses/useUserRoles";
import { millisBefore, millisBetweenDates } from "@/utils/dateUtils";
import idleTimeout from "idle-timeout";
import IdleTimeout from "idle-timeout/dist/IdleTimeout";

const uuidv4 =()=> {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

export enum ErrorAction {
  LogIn,
  LogInWithLogOut,
  ResetPassword,
}

export interface AuthState {
  loading: boolean;
  isAuthenticated: boolean;
  user: User | null;
  userRoles: UserRole[] | null;
  userSiteId: string | null;
  token: string | null;
  parsedToken: ParsedToken | null,
  //auth0Client: Auth0Client | null;
  popupOpen: boolean;
  message: {
    header?: string;
    text?: string;
    button?: string;
    action?: ErrorAction;
  } | null;
  isForceLogOut: boolean;
}

export interface ParsedToken {
  exp: number;
  clientId: string;
  sub: string;
  email: string;
  name: string;
  role: number;
  roleName: string;
  siteId: string | null;
  status: number;
  statusName: string;
}

export enum AuthMutations {}

export enum AuthActions {
  // CREATE_CLIENT = "CREATE_CLIENT",
  UPDATE_TOKEN = "UPDATE_TOKEN",
  EXPIRE_TOKEN = "EXPIRE_TOKEN",
  LOG_OUT = "LOG_OUT",
  LOG_IN = "LOG_IN",
  CURRENT_USER_PROFILE = "CURRENT_USER_PROFILE"
}

export const idleTimer: IdleTimeout = idleTimeout(
  () => {
    store.dispatch(AuthActions.LOG_OUT);
  },
  {
    element: document.body,
    timeout: 5 * 60 * 1000,
    loop: true,
  }
);

idleTimer.pause();

function getMessageObject(params: any) {
  // return {
  //   header: "Your account has been deactivated. ",
  //   text: "Please contact GTDiagnostics team if you have any questions",
  //   button: "Go back to Log In",
  //   action: ErrorAction.LogIn
  // };

  if (params.error || params.message) {
    switch (params.error || params.message) {
      // +
      // case "not-activated":
      //   {
      //     // if (params.error_description === "user is blocked") {
      //     //   return {
      //     //     header: "Your account has been deactivated. ",
      //     //     text:
      //     //       "Please contact GTDiagnostics team if you have any questions",
      //     //     button: "Go back to Log In",
      //     //     action: ErrorAction.LogIn,
      //     //   };
      //     // }
      //     // if (
      //     //   params.error_description.includes(
      //     //     "Please verify your email before logging in."
      //     //   ))
      //      {
      //       return {
      //         header: "Your account hasn't been activated yet.",
      //         text: `Please enter valid credentials. If you do not have account registered with HiPAL yet, please sign up first`,
      //         button: "Go back to Log In",
      //         action: ErrorAction.LogIn,
      //       };
      //     }
      //   }
      //   break;



      case "email-link-expired": {
        return {
          header: "Link expired",
          text:
          "Sorry, your activation link is expired.",
          // button: "Forgot password",
          // action: ErrorAction.ResetPassword,
        };
      }

      case "user-deactivated": {
        return {
          header: "Your account has been deactivated.",
          text:
            "Please contact GT Diagnostics team <a href=\"mailto:" + supportEmail + "\">" + supportEmail + "</a>" + " if you have any questions",
          button: "Go back to Log In",
          action: ErrorAction.LogIn,
        };
      }

      case "reset-password-link-expired": {
        return {
          header: "Link expired",
          text:
            "We are sorry, but this link is expired. Please reset your password again.",
          // button: "Forgot password",
          // action: ErrorAction.ResetPassword,
        };
      }

      // // +
      // case "Email with change link is sent": {
      //   return {
      //     header: "Email is sent",
      //     text: `Reset password email has been sent. Please check your mailbox and follow the link from there to reset your password. If you haven't received a validation email within 24 hours, please contact HiPAL support team <a href="mailto:${supportEmail}">${supportEmail}</a> for further assistance`,
      //   };
      // }
      // +
      // case "This URL can be used only once": {
      //   return {
      //     // header: "Second use of change link",
      //     text:
      //       "Reset password link has been already used. Please request sending new email to reset your password",
      //     button: "Forgot password",
      //     action: ErrorAction.ResetPassword,
      //   };
      // }
      //+
      case "password-reset-success": {
        return {
          header: "Successful password change",
          text: "Your password was successfully set. Now you can log in.",
          button: "Log In",
          action: ErrorAction.LogIn,
        };
      }

      case "email-verification-success": {
        return {
          header: "Successful email verification",
          text: "The email is successfully confirmed. Now you can return to GT Diagnostic Web Portal and log in.",
          button: "Go back to Log In",
          action: ErrorAction.LogIn,
        };
      }
    }
    return {
      header: params.error,
      text: params.message || params.error_description,
      button: "Go back to Log In",
      action: ErrorAction.LogIn,
    };
  }
}

const authModule: Module<AuthState, RootState> = {
  state: () => {
    return {
      loading: true,
      isAuthenticated: false,
      user: {},
      userRoles: null,
      userSiteId: null,
      token: null,
      parsedToken:null,
      auth0Client: null,
      popupOpen: false,
      message: null,
      isForceLogOut: false,
    };
  },
  actions: {
    // async [AuthActions.CREATE_CLIENT](
    //   { state, dispatch },
    //   isRelogin?: boolean
    // ) {
    //   console.log("isRelogin: ", isRelogin);
    //   console.log("CC");
    //   // debugger;
    //   const params = parseGetParams(window.location.search);
    //   // console.log("search params: ", params);
    //   const client = await createAuth0Client({
    //     domain: domain,
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     client_id: clientId,
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     redirect_uri: window.location.origin,
    //     // audience: options.audience,
    //   });
    //   console.log("window.location.origin: ", window.location.origin);
    //   state.auth0Client = client;

    //   const message = getMessageObject(params);
    //   if (message) {
    //     state.message = message;
    //     return;
    //   }

    //   if (!params.code && !isRelogin) {
    //     const isFirstTab = +(localStorage.getItem("tabCounter") || "0") <= 1;
    //     console.log("isFirstTab: ", isFirstTab);
    //     const lastLogout = localStorage.getItem("lastLogOut");
    //     console.log("lastLogout: ", lastLogout);
    //     if (
    //       isFirstTab &&
    //       !window.location.origin.includes("localhost") &&
    //       lastLogout
    //     ) {
    //       const timeAfterLastLogOut = millisBetweenDates(
    //         new Date(),
    //         new Date(lastLogout)
    //       );
    //       console.log("timeAfterLastLogOut: ", timeAfterLastLogOut);
    //       if (timeAfterLastLogOut > 10000) {
    //         await dispatch(AuthActions.LOG_OUT);

    //         return;
    //       }
    //     }
    //   }

    //   try {
    //     // If the user is returning to the app after authentication...
    //     if (params.code && params.state) {
    //       // handle the redirect and retrieve tokens
    //       const { appState } = await state.auth0Client.handleRedirectCallback();

    //       // Notify subscribers that the redirect callback has happened, passing the appState
    //       // (useful for retrieving any pre-authentication state)
    //       onRedirectCallback(appState);
    //     }
    //   } catch (e) {
    //     state.message = e;
    //   } finally {
    //     // Initialize our internal authentication state
    //     // console.log("isAuthenticated");

    //     state.isAuthenticated = await client.isAuthenticated();
    //     console.log("state.isAuthenticated: ", state.isAuthenticated);
    //     const token = await client.getIdTokenClaims();
    //     state.token = token?.__raw || null;
    //     console.log("token updated", token?.__raw);

    //     console.log(
    //       "await client.isAuthenticated(): ",
    //       await client.isAuthenticated()
    //     );

    //     console.log("token will expire in ", millisBefore(token?.exp || 0));
    //     if (token?.exp || 0 > 0) {
    //       setTimeout(
    //         () => dispatch(AuthActions.CREATE_CLIENT, true),
    //         millisBefore(token?.exp || 0)
    //       );
    //     } else {
    //       location.reload();
    //     }

    //     localStorage.setItem("user_token", state.token || "");

    //     state.user = (await state.auth0Client.getUser()) || null;

    //     if (state.user) {
    //       const keyRoles = Object.keys(state.user).find((n) =>
    //         n.includes("roles")
    //       );
    //       if (keyRoles) {
    //         state.userRoles = state.user[keyRoles];
    //       }
    //       const keySiteId = Object.keys(state.user).find((n) =>
    //         n.includes("siteId")
    //       );
    //       if (keySiteId) {
    //         state.userSiteId = state.user[keySiteId];
    //       }
    //     }

    //     state.loading = false;
    //   }
    // },
    async [AuthActions.LOG_IN]({ state }, payload) {
      // const { targetUrl, params } = payload || {};

      // await state.auth0Client?.loginWithRedirect({
      //   appState: { targetUrl: targetUrl || "" },
      //   isWeb: true,
      //   ...params,
      // });
      const params = parseGetParams(window.location.search);
      const message = getMessageObject(params);
      if (message) {
        state.message = message;
        return;
      }
      
      const userTokenFromStorage = localStorage.getItem("user_token") || null;
      const expirationTimeFromStorage = localStorage.getItem("expires_in") || null;
      if (userTokenFromStorage) {
        const base64Url = userTokenFromStorage.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const responseToken = JSON.parse(jsonPayload);
        state.parsedToken = {
          clientId: responseToken["client_id"],
          exp: responseToken["exp"],
          email: responseToken["email"],
          name: responseToken["name"],
          role: responseToken["role"],
          roleName: responseToken["role_name"],
          sub: responseToken["sub"],
          siteId: responseToken["site_id"] ? responseToken["site_id"] : null,
          status: responseToken["status"],
          statusName: responseToken["status_name"]
        }
        state.token = userTokenFromStorage;
        state.isAuthenticated = true;
        state.user = state.parsedToken ? {
          email: state.parsedToken.email,
          name: state.parsedToken.name
        } : null;
        
        if (state.user) {

          // const keyRoles = Object.keys(state.user).find((n) =>
          //   n.includes("roles")
          // );
          // if (keyRoles) {
          //   state.userRoles = state.user[keyRoles];
          // }
          if (state.parsedToken?.roleName) {
            state.userRoles = [state.parsedToken.roleName as UserRole]
          }

          state.userSiteId = state.parsedToken.siteId || null;

          localStorage.setItem("idle_timer_init",new Date().toString());
          idleTimer.resume();

        }       
         
        state.loading = false;

        router.push("Users");
      }
      else {
        const params = parseGetParams(window.location.search);

        if (params.code && params.state) {

          let responseToken;

          const tokenUrl = new URL(`${domain}/connect/token`);

          const formBody = `${encodeURIComponent("client_id")}=${encodeURIComponent(clientId)}`
            + `&${encodeURIComponent("client_secret")}=${encodeURIComponent(clientSecret)}`
            + `&${encodeURIComponent("code")}=${params.code}`
            + `&${encodeURIComponent("redirect_uri")}=${encodeURIComponent(window.location.origin)}`
            + `&${encodeURIComponent("scope")}=${encodeURIComponent("openid profile email offline_access")}`
            + `&${encodeURIComponent("grant_type")}=${encodeURIComponent("authorization_code")}`;

          const result = await fetch(tokenUrl.href, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
          })
            .then(response => response.json())
            .then(data => {
              const accessToken = data.access_token;
              const expiresIn = data.expires_in;
              localStorage.setItem("user_token", accessToken || "");
              localStorage.setItem("expires_in", expiresIn || "");

              window.location.href = window.location.href.split('?')[0];
            })
            .catch(error=>{
              store.dispatch(AuthActions.LOG_OUT);
            });
        }
        else {
          const currentUrl = window.location.origin;
          const loginState = uuidv4();
          const loginUrl = new URL(`${domain}/connect/authorize`);
          loginUrl.searchParams.append("client_id", clientId);
          loginUrl.searchParams.append("scope", "openid profile email offline_access");
          loginUrl.searchParams.append("redirect_uri", currentUrl);
          loginUrl.searchParams.append("response_type", "code");
          loginUrl.searchParams.append("state", loginState)

          window.location.href = loginUrl.href;
        }
      }
    },
    async [AuthActions.LOG_OUT]({ state }) {
      state.isForceLogOut = true;
      state.token = null;
      state.parsedToken = null;
      state.user = null;
      localStorage.setItem("logout", "true");
      localStorage.removeItem("user_token");
      store.dispatch(AuthActions.LOG_IN);
    },
  },

  getters: {
    isSystemAdmin(state) {
      return state.userRoles?.includes(UserRole.SystemAdmin);
    },
    userSiteId(state) {
      const roles = state.userRoles || [];
      if (
        roles.includes(UserRole.SiteAdmin) ||
        roles.includes(UserRole.Clinician)
      ) {
        return state.userSiteId;
      }
      return null;
    },
    showSitePartForUser: (state) => (askLink: string) => {
      // console.log("showSitePartForUser", askLink);
      if (state.userRoles === null) return false;

      const { SystemAdmin, SiteAdmin, Clinician, Analyst } = UserRole;

      if (state.userRoles.includes(UserRole.SystemAdmin) && askLink.startsWith('/subjects')){
        return false;
      }

      const permissions = [
        { roles: [SystemAdmin], link: "/studies" },
        { roles: [SystemAdmin, SiteAdmin], link: "/sites" },
        { roles: [SystemAdmin, SiteAdmin], link: "/users" },
      ];

      for (let i = 0; i < permissions.length; i++) {
        const { roles, link } = permissions[i];
        if (askLink.startsWith(link))
          return intersection(roles, state.userRoles).length;
      }

      return true;
    },
  },
};

export const AuthModule = authModule;
