<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="kmwdvt4wpa"
        d="M4 14h8v2H4v-2zm0-4h8v2H4v-2zm6-10H2C.9 0 0 .9 0 2v16c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6zm4 18H2V2h7v5h5v11z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-24 -396) translate(0 376) translate(24 20)"
            />
            <path
              d="M3 3L21 3 21 21 3 21z"
              transform="translate(-24 -396) translate(0 376) translate(24 20)"
            />
            <g
              transform="translate(-24 -396) translate(0 376) translate(24 20) translate(4 2)"
            >
              <mask id="2s7irfs58b" fill="#fff">
                <use xlink:href="#kmwdvt4wpa" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#kmwdvt4wpa" />
              <g fill="#B0BEC5" mask="url(#2s7irfs58b)">
                <path d="M0 0H24V24H0z" transform="translate(-4 -2)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
