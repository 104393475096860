<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path id="vhh6fa2g4a" d="M10.59 0.59L6 5.17 1.41 0.59 0 2 6 8 12 2z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M24 24L0 24 0 0 24 0z"
                opacity=".87"
                transform="translate(-1392 -24) translate(72) translate(1215 17) translate(105 7)"
              />
              <g
                transform="translate(-1392 -24) translate(72) translate(1215 17) translate(105 7) translate(6 8)"
              >
                <mask id="gx3kvyrosb" fill="#fff">
                  <use xlink:href="#vhh6fa2g4a" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#vhh6fa2g4a" />
                <g fill="#90A4AE" mask="url(#gx3kvyrosb)">
                  <path d="M0 0H24V24H0z" transform="translate(-6 -8)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
