<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="agddqs9dna"
        d="M16.5 0h-14C1.12 0 0 1.12 0 2.5v19C0 22.88 1.12 24 2.5 24h14c1.38 0 2.5-1.12 2.5-2.5v-19C19 1.12 17.88 0 16.5 0zm-7 23c-.83 0-1.5-.67-1.5-1.5S8.67 20 9.5 20s1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm7.5-4H2V3h15v16z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-24 -268) translate(0 248) translate(24 20)"
            />
            <path
              d="M3 3L21 3 21 21 3 21z"
              transform="translate(-24 -268) translate(0 248) translate(24 20)"
            />
            <g
              transform="translate(-24 -268) translate(0 248) translate(24 20) translate(3)"
            >
              <mask id="o740ud63yb" fill="#fff">
                <use xlink:href="#agddqs9dna" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#agddqs9dna" />
              <g fill="#B0BEC5" mask="url(#o740ud63yb)">
                <path d="M0 0H24V24H0z" transform="translate(-3)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
