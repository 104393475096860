import intersection from "lodash-es/intersection";
import { RootState, useStore } from "@/store/store";
import { Store } from "vuex";

export enum UserRole {
  SystemAdmin = "System admin",
  SiteAdmin = "Site admin",
  Clinician = "Clinician",
  Analyst = "Analyst",
}

export const userRolesList = [
  UserRole.Analyst,
  UserRole.Clinician,
  UserRole.SiteAdmin,
  UserRole.SystemAdmin,
];

export const getUserRoleTitle = (role: UserRole | null) => {
  switch (role) {
    case UserRole.SystemAdmin:
      return "System Admin";
    case UserRole.SiteAdmin:
      return "Site Admin";
    case UserRole.Clinician:
      return "Clinician";
    case UserRole.Analyst:
      return "Analyst";
    default:
      return "Unknown role";
  }
};

export default function useUserRoles(store: Store<RootState>) {
  const hasRole = function(...args: UserRole[]) {
    const roles = store?.state?.auth.userRoles || [];

    return !!intersection(roles, args).length;
  };

  return {
    UserRole,
    hasRole,
    forbidForRole(...args: UserRole[]) {
      return hasRole(...userRolesList.filter((r) => args.indexOf(r) === -1));
    },
    getSiteId() {
      // const store = useStore();
      return store.getters.userSiteId;
    },
  };
}
