export function parseGetParams(search: string): any {
  return search
    .replace("?", "")
    .split("&")
    .map((pair) => pair.split("="))
    .map((pair) => ({ key: pair[0], value: decodeURIComponent(pair[1]) }))
    .reduce((acc, cur) => {
      return { [cur.key]: cur.value, ...acc };
    }, {});
}
