import { MetadataMutations } from "./../store/metadataModule";
import { store } from "@/store/store";
import useUserRole, { UserRole } from "@/uses/useUserRoles";
import { AuthActions } from "@/store/authModule";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const authGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const fn = () => {
    store.commit(MetadataMutations.SET_PREV_ROUTE_NAME, from.name);

    if (store.state.auth.isAuthenticated || store.state.auth.message) {
      const { hasRole, getSiteId } = useUserRole(store);

      if (hasRole(UserRole.SystemAdmin) && to.fullPath.startsWith('/subjects')) {
        return next({ name: 'Sites' });
      }

      if (to.fullPath === "/") {
        if (hasRole(UserRole.SystemAdmin)) {
          return next({ name: "Users" });
        }
        if (hasRole(UserRole.SiteAdmin)) {
          return next(`/sites/view-site/${getSiteId()}`);
        }

        return next({ name: "Subjects" });
      }
      if (store.getters.showSitePartForUser(to.fullPath)) {
        if (to.name === "Sites") {
          if (hasRole(UserRole.SiteAdmin) && !hasRole(UserRole.SystemAdmin)) {
            console.log(`/sites/view-site/${getSiteId()}`);
            return next(`/sites/view-site/${getSiteId()}`);
          }
        }

        return next();
      } else {
        return next({ name: "Dashboard" });
      }
    }

    store.dispatch(AuthActions.LOG_IN, { targetUrl: to.fullPath });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!store.state.auth.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  store.watch(
    (state) => state.auth.loading,
    (loading: boolean) => {
      if (loading === false) {
        return fn();
      }
    }
  );
};
