import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "error-screen absolute-centered" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")
  const _component_NeedSupportComponent = _resolveComponent("NeedSupportComponent")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Logo),
      (_ctx.error.header)
        ? (_openBlock(), _createBlock("h4", _hoisted_2, _toDisplayString(_ctx.error.header), 1))
        : _createCommentVNode("", true),
      (_ctx.error.text)
        ? (_openBlock(), _createBlock("p", {
            key: 1,
            innerHTML: _ctx.error.text
          }, null, 8, ["innerHTML"]))
        : _createCommentVNode("", true),
      (_ctx.error.button)
        ? (_openBlock(), _createBlock("button", {
            key: 2,
            class: "button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.buttonClick && _ctx.buttonClick(...args)))
          }, _toDisplayString(_ctx.error.button), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_NeedSupportComponent, { class: "left-bottom" })
  ], 64))
}