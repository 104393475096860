<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <defs>
      <path
        id="ilf3iapqla"
        d="M16.667 26.458c1.191 0 2.166-.975 2.166-2.166H14.5c0 1.191.975 2.166 2.167 2.166zm6.5-6.5v-5.416c0-3.326-1.766-6.11-4.875-6.847v-.737c0-.899-.726-1.625-1.625-1.625-.9 0-1.625.726-1.625 1.625v.737c-3.099.737-4.875 3.51-4.875 6.847v5.416L8 22.125v1.083h17.333v-1.083l-2.166-2.167zM21 21.042h-8.667v-6.5c0-2.687 1.636-4.875 4.334-4.875 2.697 0 4.333 2.188 4.333 4.875v6.5z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-1207 -20) translate(264) translate(943 20)">
            <path d="M0 0H32V32H0z" />
            <path d="M4 4L28 4 28 28 4 28z" />
            <mask id="wyaarzkrsb" fill="#fff">
              <use xlink:href="#ilf3iapqla" />
            </mask>
            <use fill="#263238" fill-rule="nonzero" xlink:href="#ilf3iapqla" />
            <g fill="#90A4AE" mask="url(#wyaarzkrsb)">
              <path d="M0 0H32V32H0z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
