import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import { authGuard } from "@/auth/authGuard";

const siteMetaParent = {
  parent: "Sites",
  parentLink: "/sites",
  name: "{{name}}",
};
const sitesRoutes = [
  {
    path: "/sites",
    name: "Sites",
    component: () =>
      import(/* webpackChunkName: "sites" */ "@/views/Sites/SitesList.vue"),
  },
  {
    path: "/sites/add-site",
    name: "Add Site",
    meta: {
      parent: "Sites",
      parentLink: "/sites",
    },
    component: () =>
      import(
        /* webpackChunkName: "addSiteForm" */ "@/views/Sites/AddSite/AddSite.vue"
      ),
  },
  {
    path: "/sites/edit-site/:id",
    name: "Edit Site",
    meta: {
      parent: "Sites",
      parentLink: "/sites",
      name: "Edit {{name}}",
    },
    component: () =>
      import(
        /* webpackChunkName: "editSite" */ "@/views/Sites/EditSite/EditSite.vue"
      ),
  },
  {
    path: "/sites/view-site/:id",
    name: "View Site Root",
    meta: siteMetaParent,
    component: () =>
      import(
        /* webpackChunkName: "TabsParentSite" */ "@/views/Sites/TabsParentSite/TabsParentSite.vue"
      ),
    children: [
      {
        path: "",
        name: "View Site",
        meta: siteMetaParent,
        component: () =>
          import(
            /* webpackChunkName: "viewSite" */ "@/views/Sites/ViewSite/ViewSite.vue"
          ),
      },
      {
        path: "contacts",
        name: "Site Contacts",
        meta: siteMetaParent,
        component: () =>
          import(
            /* webpackChunkName: "SiteContacts" */ "@/views/Sites/SiteContacts/SiteContacts.vue"
          ),
      },
      {
        path: "users",
        name: "Site Users",
        meta: siteMetaParent,
        component: () =>
          import(
            /* webpackChunkName: "SiteUsers" */ "@/views/Sites/SiteUsers/SiteUsers.vue"
          ),
      },
      {
        path: "devices",
        name: "Site Devices",
        meta: siteMetaParent,
        component: () =>
          import(
            /* webpackChunkName: "SiteDevices" */ "@/views/Sites/SiteDevices/SiteDevices.vue"
          ),
      },
      {
        path: "studies",
        name: "Site Studies",
        meta: siteMetaParent,
        component: () =>
          import(
            /* webpackChunkName: "SiteStudies" */ "@/views/Sites/SiteStudies/SiteStudies.vue"
          ),
      },
    ],
  },
];

const subjectMetaParent = {
  parent: "Subjects",
  parentLink: "/subjects",
  name: "Subject {{name}}",
};
const subjectRoutes: any[] = [
  {
    path: "/subjects",
    name: "Subjects",
    component: () =>
      import(
        /* webpackChunkName: "subjectsList" */ "@/views/Subjects/SubjectsList.vue"
      ),
  },
  {
    path: "/subjects/hipal-pro",
    name: "HiPAL Pro",
    meta: { parent: "Subjects", parentLink: "/subjects" },
    component: () =>
      import(
        /* webpackChunkName: "subjectsHiPAL" */ "@/views/Subjects/HipalProSubjects/HipalProSubjects.vue"
      ),
  },
  {
    path: "/subjects/psycoxx",
    name: "PsyCoXx",
    meta: { parent: "Subjects", parentLink: "/subjects" },
    component: () =>
      import(
        /* webpackChunkName: "subjectsPsyCoXx" */ "@/views/Subjects/SubjectsList.vue"
      ),
  },
  {
    path: "/subjects/add-subject",
    name: "Add Subject",
    meta: {
      parent: "Subjects",
      parentLink: "/subjects",
    },
    component: () =>
      import(
        /* webpackChunkName: "addSubject" */ "@/views/Subjects/AddSubject/AddSubject.vue"
      ),
  },
  {
    path: "/subjects/edit-subject/:siteId?/:id",
    name: "Edit Subject",
    meta: {
      parent: "Subjects",
      parentLink: "/subjects",
      name: "Edit {{name}}",
    },
    component: () =>
      import(
        /* webpackChunkName: "editSubject" */ "@/views/Subjects/EditSubject/EditSubject.vue"
      ),
  },
  {
    path: "/subjects/view-subject/:siteId?/:id",
    name: "View Subject Root",
    meta: subjectMetaParent,
    component: () =>
      import(
        /* webpackChunkName: "TabsParentSubject" */ "@/views/Subjects/TabsParentSubject/TabsParentSubject.vue"
      ),
    children: [
      {
        path: "",
        name: "View Subject",
        meta: subjectMetaParent,
        component: () =>
          import(
            /* webpackChunkName: "viewSubjectCard" */ "@/views/Subjects/ViewSubject/ViewSubject.vue"
          ),
      },
      {
        path: "hipal-pro/:assessmentId?",
        name: "View HiPAL Pro",
        meta: subjectMetaParent,
        component: () =>
          import(
            /* webpackChunkName: "SubjectsHiPALProTab" */ "@/views/Subjects/HiPALProTab/HiPALProTab.vue"
          ),
      },
    ],
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  ...sitesRoutes,
  {
    path: "/devices",
    name: "Devices",
    component: () =>
      import(/* webpackChunkName: "devices" */ "@/views/Devices/Devices.vue"),
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/Users/UsersList.vue"),
  },
  {
    path: "/users/add-user",
    name: "Add User",
    meta: {
      parent: "Users",
      parentLink: "/users",
    },
    component: () =>
      import(
        /* webpackChunkName: "addUserForm" */ "@/views/Users/AddUser/AddUser.vue"
      ),
  },
  {
    path: "/users/view-user/:id",
    name: "View User",
    meta: {
      parent: "Users",
      parentLink: "/users",
      name: "{{name}}",
    },
    component: () =>
      import(
        /* webpackChunkName: "viewUser" */ "@/views/Users/ViewUser/ViewUser.vue"
      ),
  },
  {
    path: "/users/edit-user/:id",
    name: "Edit User",
    meta: {
      parent: "Users",
      parentLink: "/users",
      name: "Edit {{name}} profile",
    },
    component: () =>
      import(
        /* webpackChunkName: "viewUser" */ "@/views/Users/EditUser/EditUser.vue"
      ),
  },
  {
    path: "/studies",
    name: "Studies",
    component: () =>
      import(/* webpackChunkName: "studies" */ "@/views/Studies/Studies.vue"),
  },
  ...subjectRoutes,
  {
    path: "/system-settings",
    name: "System Settings",
    component: () =>
      import(
        /* webpackChunkName: "system-settings" */ "@/views/SystemSettings/SystemSettings.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(authGuard);

export default router;
